<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <dark-Toggler class="d-none d-lg-block" />
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ userData.name }}
            </p>
            <span class="user-status">{{ userData.organisation.name }}</span>
          </div>
          <b-avatar
            size="40"
            :variant="`light-${resolveUserRoleVariant(userData.role)}`"
            :src="userData.icon_url"
          />
        </template>

        <b-dropdown-item link-class="d-flex align-items-center" :href="$themeConfig.app.membersUrl">
          <feather-icon
              size="16"
              icon="ExternalLinkIcon"
              class="mr-50"
          />
          <span>Go to extranet</span>
        </b-dropdown-item>

        <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>Logout</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar,
} from 'bootstrap-vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import { ref } from '@vue/composition-api'
import useJwt from '@/auth/jwt/useJwt'
import useUsersList from '@/views/user/users-list/useUsersList'
import { $themeConfig } from '@/../themeConfig'


export default {
  setup() {
    const {
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,
    } = useUsersList()

    const userData = ref({})
    userData.value = useJwt.getUserData()

    return {
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,
      userData,
      $themeConfig,
    }
  },
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,

    // Navbar Components
    DarkToggler,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  methods: {
    logout() {
      const userData = useJwt.getUserData()
      const logoutUrl = userData.logoutUrl
      useJwt.clearUserData()
      window.location.href = logoutUrl
    },
  },
}
</script>
