export default [
  {
    title: 'Home',
    route: 'home',
    icon: 'HomeIcon',
  },
  {
    title: 'My Organisation',
    route: 'myorg-view',
    icon: 'BriefcaseIcon',
  },
]
